<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6" />
      </b-row>
    </div>
  </div>
</template>

<script>
import {APIService} from "../../services/api";

export default {
  name: 'Logout',
  async beforeMount() {
    await localStorage.setItem('token', null);
    await APIService.setToken(null);
    await this.$router.push('/pages/login');
  },
}
</script>
